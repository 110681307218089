@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .animate-fadeIn {
    animation: fadeIn 0.5s ease-out;
  }
  
  #terminal-output {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* For Firefox */
  
    /* For Chrome, Safari, and Edge */
    &::-webkit-scrollbar {
      width: 6px; /* Minimal width */
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* Transparent track */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2); /* Color of the scroll thumb */
      border-radius: 10px; /* Rounded scrollbar */
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(255, 255, 255, 0.5); /* Change color on hover */
    }
  }
  